/**
 * ==== Bootstrap Plugins ====
 * Bootstrap plugins are auto-invoked
 */
import Collapse from "bootstrap/js/dist/collapse";
import masonry from "masonry-layout";
import featherlight from "featherlight";

/**
 * ==== Helpers on page load ====
 * - pageInit: Run some general stuff
 * - resetPage: Move footer to the bottom of the page
 */
import { resetPage, pageInit } from "./theme/reset";
// import { scrollHeader } from "./theme/scroll";
// import { sliderConfiguration } from "./theme/sliders";
import { videoControls } from "./theme/video";

pageInit();
resetPage();
// scrollHeader();
// sliderConfiguration();
videoControls();

/**
 * Run functions on window-resize
 * - resetPage: Move footer to the bottom of the page
 */
window.addEventListener("resize", function () {
	resetPage();
});
